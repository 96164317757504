import React, { useState } from "react"
import { Link } from "@StarberryUtils";
import "../LatestDevelopments/DevelopmentCard.scss";
import Modal from "react-bootstrap/Modal"
import BookViewingForm from "../../components/forms/book-a-viewing-form"
// markup

import { ImageModule } from "../../modules/Image_Module";


const DevelopmentCard = (props) => {

    const [isOpen,setIsOpen] = useState(false);
    //console.log("cardddd", props)
    return (
        <div className="contact-card">
            <div className="development-img-wrap col-xl-12 col-md-12 col-lg-12">
                
                    <Link to={props.cardUrl} className="development-img img-zoom">
                        <figure>
                           
                            {

                                <ImageModule ImageSrc={props.cardImg} imagename={'insight.Tile_Image.tile_image'} article_id={props.article_id} imagetransforms={props.imagetransforms} ggfxNew={true} />

                            }

                        </figure>
                    </Link>
              
            </div>
            <div className="col-xl-12 col-lg-12 col-md-12">
                <div className="development-info">
                    <h3>
                        <Link>
                           {props?.cardTitle}
                        </Link>
                    </h3>

                    <ul className="about-info">
                            <li>
                                <strong className="">
                                    Project Type:&nbsp;
                                </strong>
                                <span className="">
                                   {props?.projectType}
                                </span>
                            </li>
                            <li>
                                <strong className="">
                                Plot Size:&nbsp;
                                </strong>
                                <span className="">
                                   {props?.plotSize}
                                   {/* 1,000 sq.m */}
                                </span>
                            </li>
                            <li>
                                <strong className="">
                                    Price Range:&nbsp;
                                </strong>
                                <span className="price-range">
                                  {props?.priceRange.replaceAll("QAR","")} QAR
                                </span>
                            </li>
                        </ul>
                    <div className="btn-wrapper">

                        <a href="#contact-form" className="btn btn-primary"><span>Request More Details</span></a> 
                        <a className="btn btn-outline"  onClick={(e) => setIsOpen(true)}><span>Schedule a Visit</span></a>

                    </div>

                </div>
            </div>
            <Modal
        id="valuation_modal"
        show={isOpen}
        onHide={() => setIsOpen(false)}
        backdrop="static"
        keyboard={false}
        className="member-contact-modal"
      >
        {/* <Button variant="" onClick={this.closeModal} className="p-0 closepopup"><i className="icon icon-close-popup"></i></Button> */}
        <Modal.Header closeButton className="contact-close-btn memberPopup">
          <Modal.Title className="w-100"><h3 className="text-center content-primary-color mb-0">Book a Viewing</h3></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <BookViewingForm to_email_id={""} formName={`Book a Viewing`} location={props.location} formLabel={""} formType={'book_a_viewing'} />
        </Modal.Body>
      </Modal>
        </div>
    )
}

export default DevelopmentCard