import { Link } from "@StarberryUtils";
import React, { useEffect, useState, useCallback } from "react"
import {
    Button,
    Navbar,
    Nav,
    Form,
    Container,
    FormControl,
    Row,
    Col,
} from "react-bootstrap"
import "./ContactDetails.scss"

import ImgPerson from "../../../images/person.jpg"
import SelectBox from "../../Home/SelectBox/SelectBox"
import HTMLReactParser from 'html-react-parser';
import ContactForm from '../../forms/contact-form';
import MortgageContactForm from '../../forms/mortgage-contact-form';

import Modal from "react-bootstrap/Modal"
import MemberForm from "../../forms/member-form"
import { useLocation } from "@reach/router";
import logo from '../../../images/favicon.png'
import { removeDataAttributes } from "../../../comQueryStructure";

const ContactDetails = (props) => {
    const [value, setValue] = useState("");
    const [typeVal, setTypeVal] = useState(null);
    const [render, setRender] = useState(false);
    const [isOpen, setIsOpen] = useState(false);

    const enquiryType = [
        { value: 'Office Consultation', label: 'Office Consultation' },
        { value: 'Office Consultation 2', label: 'Office Consultation 2' },
        { value: 'Office Consultation 3', label: 'Office Consultation 3' },
        { value: 'Office Consultation 4', label: 'Office Consultation 4' },
    ];

    var office_data = props.Modules && props.Modules.choose_office;
    let contact_office = removeDataAttributes(office_data)

    const location = useLocation();
    useEffect(() => {
        let params = new URLSearchParams(location.search);
        if (typeVal == null) {  
            if (params.get("action")) {
                setTypeVal(params.get("action"))
            }else{
                setTypeVal(localStorage.getItem('typeVal'))
            }
           
        }
        if (!render) {
            setRender(true)
        }
        

    })

    // alert(location.href);
    // console.log('mk props', props)

    let Name = contact_office?.Name;

    return (
        <React.Fragment>
            <div className="contact-personal-application text-center" id="contact-form">
                <Container>
                    <Row>
                        <Col xl={8} lg={8} md={12} className="contact-left-block">
                        {props.GQLPage.Alias === "land-and-developments" &&
                           <h2 className="contact-form-heading">Contact us</h2>}
                            <p className="form-title">
                                {props.Content && HTMLReactParser(props.Content)}
                            </p>
                             <div className="animated">
                                {render && props.GQLPage.Alias === "mortgage-contact" &&
                                    <MortgageContactForm typeVal={typeVal} to_email_id={contact_office.Email} url={location.href} alias={props.GQLPage.Alias} classNames="application-form text-left" />
                                }
                                {render && props.GQLPage.Alias !== "mortgage-contact" &&
                                    <ContactForm typeVal={typeVal} to_email_id={contact_office.Email} url={location.href} alias={props.GQLPage.Alias} classNames="application-form text-left" />
                                }

                             </div>
                        </Col>

                        <Col xl={4} lg={4} md={12} className="negotiator-right-black-section">
                             <div className="animated">
                                <div className="contact-block-content">
                                    {contact_office && <>
                                        <div className="negotiator d-flex align-items-center">
                                            {
                                                contact_office.Image &&
                                                <figure>
                                                    {Name != "Riya" ? <img src={contact_office.Image.url} alt="profile" /> : <img src={logo} alt="profile" />}
                                                </figure>
                                            }

                                            <div className="info">
                                                {Name != "Riya" && <strong className="name d-block">{contact_office.Name}</strong>}
                                                <div className="contact-nav">
                                                    <Link href={`tel:${contact_office.Direct_Phone}`}>{contact_office.Direct_Phone}</Link>
                                                    <span>|</span>
                                                    <a href={"javascript:;"} onClick={() => setIsOpen(true)}> Email {Name != "Riya" && contact_office.Name.split(' ')[0]}</a>
                                                </div>
                                            </div>
                                        </div>

                                        <h2>Contact Details</h2>
                                        <ul className="project-info">
                                            <li>
                                                <strong>Office :</strong>
                                                <span><a href={`tel:${contact_office.Office_Phone}`}>{contact_office.Office_Phone}</a></span>
                                            </li>
                                            <li>
                                                <strong>Direct :</strong>
                                                <span><a href={`tel:${contact_office.Direct_Phone}`}>{contact_office.Direct_Phone}</a></span>
                                            </li>
                                            <li>
                                                <strong>Email Id :</strong>
                                                <span className="email-font">
                                                    {/* <a href={`mailto:${contact_office.Email}`}>{contact_office.Email}</a> */}
                                                    <a href={"javascript:;"} onClick={() => setIsOpen(true)}>{contact_office.Email}</a>
                                                </span>
                                            </li>
                                        </ul>

                                        {props.GQLPage.Alias !== "land-and-developments" &&
                                            contact_office.Opening_Hours && contact_office.Opening_Hours.length > 0 &&
                                            <>
                                                <h2>Opening Hours</h2>
                                                <ul className="project-info opening_hrs">
                                                    {
                                                        contact_office.Opening_Hours.map((item, index) => {
                                                            return (
                                                                <li>
                                                                    <strong>{item.Week_Day}</strong>
                                                                    <span>{item.Timing}</span>
                                                                </li>
                                                            )
                                                        })
                                                    }
                                                </ul>
                                            </>
                                        }

                                    </>
                                    }
                                </div>
                             </div>
                        </Col>
                    </Row>

                    <Modal
                        id="valuation_modal"
                        show={isOpen}
                        onHide={() => setIsOpen(false)}
                        backdrop="static"
                        keyboard={false}
                        className="member-contact-modal"
                    >
                        {/* <Button variant="" onClick={this.closeModal} className="p-0 closepopup"><i className="icon icon-close-popup"></i></Button> */}


                        <Modal.Header closeButton className="contact-close-btn memberPopup">
                            <Modal.Title className="w-100"><h3 className="text-center content-primary-color mb-0">Contact - {contact_office.Name}</h3></Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <MemberForm to_email_id={contact_office.Email} formName={`Individual Contact`} formLabel={`Contact ${contact_office.Name}`} />
                        </Modal.Body>
                    </Modal>

                </Container>
            </div>

        </React.Fragment>
    )
}

export default ContactDetails
